@import "../../../../../../assets/styles/settings/colors";

.curatorHeader {
  background-color: $placeholder_container_color;
  background-size: cover !important;
  background-position: top center !important;
  height: 275px;
  position: relative;

  .curatorHeaderContainer {
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0.75) 0%,
            rgba(255, 255, 255, 0.75) 75%,
            rgba(255, 255, 255, 1) 100%
    );
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .curatorHeaderPicture {
      width: 175px;
      height: 175px;
      background-color: $background_color;
      background-size: cover !important;
      background-position: top center !important;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5%;
    }
  }
}

.playlistGrid {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 15px 10px;
  }
}
