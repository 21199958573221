@import '../../../assets/styles/settings/colors';

.player {
  background: $container_background;
  padding: 20px 15px;

  .main-info, .mainInfo {
    display: flex;
    flex-direction: row;
    width: 100%;

    > div.picture {
      border-radius: 4px;

      > .image {
        width: 60px;
        height: 60px;
        border-radius: inherit;
      }
    }

    > .track {
      flex: 1;
      padding-left: 10px;

      .artistName, .albumName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 160px;
        display: block;
        font-size: 12px;

        &.link {
          &:hover {
            cursor: pointer;
            color: $accent_color;
          }
        }
      }

      .artistName {
        font-weight: bold;
      }

      .albumName {
        margin-top: 3px;
        font-weight: 300;
      }

      h1 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 160px;
        display: block;
        font-size: 18px;
        margin-top: 2px;
        margin-bottom: 3px;
      }
    }
  }

  .progressBarWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    span {
      font-size: 11px;
      color: $player_secondary_button_color;
    }

    .playbackTime {
      margin-right: 8px;
    }

    .playbackDuration {
      margin-left: 8px;
    }
  }

  .progress-bar, .progressBar {
    margin: 4px auto;
    width: 100%;
    height: 4px;
    outline: none;
    border-radius: 2px;
    background: linear-gradient(
        to right,
        $accent_color 0%,
        $accent_color 50%,
        white 50%,
        white 100%
      )
      no-repeat;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
      -webkit-appearance: none;
    }

    &::-webkit-slider-thumb {
      height: 10px;
      width: 5px;
      border: 0;
      background: $tertiary_color;
      border-radius: 2px;
      -webkit-appearance: none;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    > span {
      margin: 0 10px;
      font-size: 20px;
      line-height: 24px;
      color: $player_button_color;
      cursor: pointer;

      &.main {
        font-size: 24px;
      }

      &.controls {
        font-size: 14px;
        margin-top: 20px;
        display: flex;
        flex: 1;
        justify-content: center;
        color: $player_secondary_button_color;
      }

      &:hover {
        color: $tertiary_color;
      }

      &:active i {
        transform: scale(0.95);
      }

      &.enabled {
        color: $accent_color;
        &:hover {
          color: darken($accent_color, 10%);
        }
      }

      &.shuffle.one {
        position: relative;

        &:after {
          content: '1';
          position: absolute;
          top: 8px;
          right: 9px;
          height: 14px;
          width: 9px;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          background: $container_background;
          border-radius: 2px;
          text-align: center;
        }
      }
    }
  }
}

.volumeControlWrapper {
  position: relative;

  > i {
    position: absolute;
    z-index: 10;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    margin-top: -5px;
  }

  > .volumeControlContainer {
    position: absolute;
    display: none;
    z-index: 5;
    background: $player_volume_container_color;
    border-radius: 5px;
    width: 30px;
    height: 120px;
    bottom: -5px;
    box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.25);
    align-items: center;
  }

  &:hover > .volumeControlContainer {
    display: block;
  }

  .volumeBarWrapper {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;

    .volumeBar {
      transform: rotate(-90deg);
      width: 80px;
    }
  }
}
