@import '../../../assets/styles/settings/colors';

.pageTitle {
  margin: 15px 0;
  cursor: default;

  .contextHeading {
	text-transform: uppercase;
	color: $accent_color;
	font-size: 12px;
  }

  h1 {
    margin: 3px 0 0;
  }
}
