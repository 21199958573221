.react-contextmenu {
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  color: #373a3c;
  font-size: 14px;
  margin: 0;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;

  &.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }

  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.2;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;

    &.react-contextmenu-item--active, &.react-contextmenu-item--selected {
      color: #fff;
      background-color: #fe2851;
      border-color: #fe2851;
      text-decoration: none;
    }

    &.react-contextmenu-item--disabled {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.15);
      color: #878a8c;

      &:hover {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.15);
        color: #878a8c;
      }
    }
  }

  .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;

    &:hover {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.15);
    }
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;

    > .react-contextmenu-item:after {
      content: "▶";
      display: inline-block;
      position: absolute;
      right: 7px;
    }
  }
}

