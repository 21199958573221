@import '../../../../assets/styles/settings/colors';

.trackGrid {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: auto auto auto auto;
  grid-gap: 0 20px;
  padding: 5px 0;
  justify-content: left;
  width: 0; // WTF, this shouldn't work but it makes it work and nothing else does. One day it may break, but until that day lets just completely forget that this is a thing...

  .track {
    width: 350px;
    height: 50px;

    // This is getting a little bit too "creative" now...
    &:nth-child(4n + 1) > :first-child > :first-child:before {
      display: none;
    }
  }
}
