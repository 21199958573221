@import '../../../../assets/styles/common.scss';
@import '../../../../assets/styles/settings/colors';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin: 15px 10px;
  }
}

.artistList {
  @extend .scrollWrapperThin;

  width: 250px;
  height: 100%;
  border-right: solid 1px $container_background;
  overflow: auto;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      margin: 0;
      width: 100%;

      .artist {
        height: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        cursor: pointer;
        align-items: center;
        font-weight: 500;
      }

      .artistBacker {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 0;
        right: 0;

        &:before {
          content: '';
          position: absolute;
          z-index: -2;
          left: 70px;
          right: 10px;
          top: 1px;
          height: 1px;
          display: block;
          background: $track_background_color;
        }
      }

      & .artistBacker:after {
        content: '';
        background: none;
        transition: background 0.15s;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      &:hover .artistBacker:after {
        background: $container_background;
      }

      &:first-child .artistBacker:before {
        background: none;
      }

      .activeArtist {
        color: $accent_color;
        .artistBacker:after {
          background: $container_background;
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .pictureWrapper {
        display: flex;
        width: 41px;
        height: 41px;
        margin-left: 15px;
        background: $placeholder_container_color;
        border-radius: 50%;
        align-items: center;
        justify-content: center;

        > img {
          border-radius: inherit;
        }

        > span {
          color: lighten($tertiary_color, 25%);
        }
      }

      .artistName {
        margin-left: 10px;
        font-size: 12px;
      }
    }
  }
}
