@import 'settings/colors';

body {
  min-height: 100vh;
  margin: 0;
  background: $background_color;
  color: $text_color_strong;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, ' Helvetica Neue', 'Roboto', sans-serif;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

div,
span {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

a {
  color: rgb(105, 105, 105);
  text-decoration: none;
  transition: color 0.1s;
  cursor: pointer;
}

a:hover {
  color: #fe2851;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  -webkit-appearance: none;
  background: none;
  border: solid 1px #fe2851;
  padding: 10px 15px;
  color: #fe2851;
  font-size: 11px;
  cursor: pointer;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
}

.scrollWrapper {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 99px;
  }

  &::-webkit-scrollbar-track {
    background: $background_color;
    border-radius: 99px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($accent_color, 40%);
    border-radius: 99px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $background_color;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: lighten($accent_color, 25%);
  }

  &::-webkit-scrollbar-thumb:active {
    background: lighten($accent_color, 18%);
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }

  // Firefox specific
  scrollbar-width: thin;
  scrollbar-color: lighten($accent_color, 40%) $background_color;
  &:hover {
    scrollbar-color: lighten($accent_color, 25%) $background_color;
  }
  &:active {
    scrollbar-color: lighten($accent_color, 18%) $background_color;
  }

  &Thin {
    @extend .scrollWrapper;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    scrollbar-width: thin; // Firefox specific
  }

  &Thick {
    @extend .scrollWrapper;
    &::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }
    scrollbar-width: unset; // Firefox specific
  }
}

:global {
  #app-root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  #main-wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    overflow: auto;

    > aside {
      overflow-y: auto;
      flex-shrink: 0;
    }

    #main-content {
      position: relative;
      display: flex;
      flex: 1;
    }
  }

  @import 'vendor/react-contextmenu';
  @import 'vendor/github-fork-ribbon-css';
  @import '~react-s-alert/dist/s-alert-default';
  @import '~react-s-alert/dist/s-alert-css-effects/slide';
  @import 'vendor/toaster';
  @import 'vendor/contextMenu';
}

@import 'musicon';
