@import '../../../../assets/styles/settings/colors';

.scrollGrid {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: auto;
  grid-gap: 0 20px;
  justify-content: left;
  padding: 5px 0;
  width: 0; // WTF, this shouldn' t work but it makes it work and nothing else does. One day it may break, but until that day lets just completely forget that this is a thing...

  &.doubleRow {
    grid-template-rows: auto auto;
  }
}
