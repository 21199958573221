@import '../../../assets/styles/settings/colors';

.panel {
  display: flex;
  flex-direction: row;

  .playActions {
    display: flex;
    justify-content: space-between;

    .button {
      width: 48%;
      height: 30px;
      animation: fadein 0.8s;
      border-color: $sidebar_color;
      background: $sidebar_color;
      color: $tertiary_color;
      border-radius: 4px;
      align-content: center;
      cursor: pointer;
      line-height: 0px;
      margin: 10px 0;

      .icon{
        font-size: 9px;
        padding-right: 5px;
      }
    }

    .button:hover {
      color: $accent_color;
      background: $indiscreet_hover_color;
    }
  }

  .aside {
    width: 220px;
  
    .artworkWrapper {
      border-radius: 8px;
      width: 220px;
      height: 220px;
      background: $container_background;
      
      img {
        border-radius: inherit;
        width: inherit;
        height: inherit;
      }
    }
    
    .albumRuntimeDescription {
      display: block;
      text-align: center;
      color: $tertiary_color;
      font-size: 12px;
      margin-top: 5px;
    }
  }
  
  .main {
    margin-left: 25px;
    flex: 1;
  
    .title {
      display: block;
      font-weight: bold;
      font-size: 2em;
      clear: both;
      overflow: auto;

      > * {
        float: left;
        line-height: normal;
      }

      .explicit > span {
          background: $secondary_color;
          color: $background_color;
          display: flex;
          flex-shrink: 0;
          width: 12px;
          height: 12px;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          border-radius: 3px;
          font-size: 9px;
          margin-left: 5px;
          margin-top: 12px;
      }
    }
    
    .subtitle {
      display: block;
      color: $tertiary_color;
      margin-bottom: 10px;
      
      > a:hover {
        color: $accent_color;
      }
    }

    .description {
      display: block;
      margin-bottom: 10px;
      line-height: 1.3em;
      cursor: pointer;

      :global {
        .read-more {
          color: $text_color_weak;
        }
      }
    }
  }

  .showCompleteContainer {
    margin-top: 10px;

    > span {
      color: $accent_color;
      cursor: pointer;
      font-size: 0.9em;
      margin-left: 35px;

      &:hover {
        color: darken($accent_color, 20%);
      }
    }
  }
}
